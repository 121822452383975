import { useState, useEffect } from "react";
import logoFull from "./images/logo-full.png";
import logoMini from "./images/logo-mini.png";
import cec from "./images/cec.png";
import amazon from "./images/amazon.png";
import baratao from "./images/baratao.png";
import imperio from "./images/imperio.png";
import puc from "./images/puc.png";
import atacadao from "./images/atacadao.png";
import casalit from "./images/casalit.png";
import masterson from "./images/masterson.png";
import itaipava from "./images/itaipava.png";
import competicao from "./images/competicao.png";
import prologis from "./images/prologis.png";
import rio2016 from "./images/rio2016.png";
import subsea from "./images/subsea.png";
import autoglass from "./images/autoglass.png";
import estacio from "./images/estacio.png";
import santander from "./images/santander.png";
import riachuelo from "./images/riachuelo.png";
import cbmerj from "./images/cbmerj.png";
import { HiDocumentCheck } from "react-icons/hi2";
import { RiPencilRuler2Fill, RiSearchEyeLine } from "react-icons/ri";
import {
  FaFireExtinguisher,
  FaSpinner,
  FaCheckCircle,
  FaWhatsapp,
} from "react-icons/fa";
import { BsConeStriped } from "react-icons/bs";
import { IoMdConstruct } from "react-icons/io";
import NotasTecnicas from "./components/NotasTecnicas";

const clientes = [
  amazon,
  baratao,
  imperio,
  cec,
  puc,
  atacadao,
  casalit,
  masterson,
  itaipava,
  competicao,
  prologis,
  rio2016,
  subsea,
  autoglass,
  estacio,
  santander,
  riachuelo,
];

function App() {
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loadingImages, setLoadingImages] = useState(true);
  const [notasTecnicas, setNotasTecnicas] = useState(false);
  const [NTData, setNTData] = useState([]);
  const [NTLoading, setNTLoading] = useState(false);
  const [NTFirst, setNTFirst] = useState(true);

  useEffect(() => {
    handleNotasTecnicas();

    const promises = clientes.map((cliente) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = cliente;
        img.onload = () => {
          resolve();
        };
      });
    });

    Promise.all(promises).then(() => {
      setLoadingImages(false);
    });
  }, []);

  const handleNotasTecnicas = () => {
    if (NTData.length < 1 && notasTecnicas === false && NTFirst === true) {
      setNTLoading(true);
      fetch(
        "https://api.scraperapi.com?api_key=5fe88478c6b124f4519e9668f94cbba0&url=https://www.cbmerj.rj.gov.br/notas-tecnicas"
      )
        .then((response) => response.text())
        .then((html) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, "text/html");

          let groupsElements = doc.querySelectorAll(".sppb-panel");

          let groups = Array.from(groupsElements).map((group) => {
            let links = Array.from(group.querySelectorAll("a")).map((link) => {
              return {
                link: `https://www.cbmerj.rj.gov.br${link.getAttribute("href")}`,
                title: link.innerText,
              };
            });
            let scrapedGroup = {
              title: group.querySelector(".sppb-panel-heading").innerText,
              links: links,
            };
            return scrapedGroup;
          });
          setNTData(groups);
          setNTLoading(false);
        });
      setNTFirst(false);
    }
  };

  const handleSubmit = (e) => {
    setSending(true);
    setSuccess(false);
    e.preventDefault();
    const data = new FormData(e.target);
    const form = {
      nome: data.get("nome"),
      telefone: data.get("telefone"),
      email: data.get("email"),
      mensagem: data.get("mensagem"),
      instalacao: data.get("instalacao") === null ? "Não" : "Sim",
      manutencao: data.get("manutencao") === null ? "Não" : "Sim",
      projeto: data.get("projeto") === null ? "Não" : "Sim",
      vistoria: data.get("vistoria") === null ? "Não" : "Sim",
      produtos: data.get("produtos") === null ? "Não" : "Sim",
      outros: data.get("outros") === null ? "Não" : "Sim",
      aceito: data.get("aceito") === null ? "Não" : "Sim",
    };
    let result = fetch(
      "https://prod-04.brazilsouth.logic.azure.com:443/workflows/261df0dbe0d5456fa27052dcc861d483/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=uCrnNtsbNS4UhY_xA4KD5pOgnMIXuYOu79Cricb_Kpk",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      }
    );

    result.then((res) => {
      if (res.status === 200) {
        setSending(false);
        setSuccess(true);

        e.target.reset();
      } else {
        setSending(false);
        setSuccess(false);
      }
    });
  };

  return (
    <div className="App min-h-screen">
      {loadingImages && (
        <div className="fixed top-0 left-0 w-full h-full bg-white z-50 flex flex-col items-center gap-2 justify-center">
          <FaSpinner className="animate-spin text-red-600 text-4xl" />
        </div>
      )}
      <header className="bg-white/70 backdrop-blur-md sticky flex flex-col top-0 z-40 shadow-md">
        <div className="container flex justify-between h-20 p-2 w-full mx-auto items-center ">
          <img className="h-14" src={logoMini} alt="logo" />
          <div className="flex items-center gap-4">
            <div className="text-right text-slate-600 font-bold text-xs">
              <p>Empresa</p>
              <p>Credenciada</p>
            </div>
            <img className="h-10" src={cbmerj} alt="cbmerj" />
          </div>
        </div>
        <button
          className="bg-red-700 text-white text-xs font-bold py-2 px-4 absolute right-4 top-4 rounded-b-xl mt-16"
          onClick={() => {
            setNotasTecnicas(!notasTecnicas);
            handleNotasTecnicas();
          }}
        >
          Confira as últimas notas técnicas.
        </button>
      </header>
      <section className="container flex flex-col items-center justify-center py-14 md:py-32 px-8 sm:px-12 md:px-24 lg:px-64 w-full mx-auto">
        <h2 className="text-4xl md:text-6xl font-bold text-center text-slate-800">
          A <span className="text-red-700">Commfire</span> faz<br></br> o que as
          outras tentam.
        </h2>
        <p className="text-md md:text-xl font-medium text-center text-slate-400 my-8">
          Contamos com produtos e serviços de alta qualidade para garantir a
          segurança de nossos clientes e assegurar a legalização de suas obras
          junto ao Corpo de Bombeiros.
        </p>
        <div className="w-full flex flex-col md:flex-row justify-center items-center gap-4">
          <button
            onClick={() => {
              document
                .getElementById("contato")
                .scrollIntoView({ behavior: "smooth" });
            }}
            className="bg-red-700 hover:bg-red-800 text-red-50 font-bold py-4 px-8 rounded-lg transition-colors"
          >
            Peça seu orçamento
          </button>
          <button
            onClick={() => {
              document
                .getElementById("servicos")
                .scrollIntoView({ behavior: "smooth" });
            }}
            className="bg-slate-200 hover:bg-slate-300 text-slate-600 font-bold py-4 px-8 rounded-lg transition-colors"
          >
            Saiba mais
          </button>
        </div>
      </section>
      <div id="servicos" className="bg-slate-100">
        <section className="container  flex flex-col items-center justify-center py-14 md:py-32 px-8 sm:px-12 md:px-24 lg:px-64 w-full mx-auto">
          <div className="text-center mb-24">
            <h1 className="text-4xl font-bold mb-6">Serviços</h1>
            <p className="text-lg font-medium text-slate-400">
              Atuamos em todas as áreas da proteção contra incêndio e pânico com
              a mais alta qualidade e eficiência.
            </p>
          </div>

          <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
            <div className="mb-4">
              <div className="bg-red-100 w-20 h-20 rounded-lg flex justify-center items-center">
                <HiDocumentCheck className="text-red-700 text-4xl" />
              </div>
              <h2 className="text-2xl font-bold text-slate-800 mt-8 mb-4">
                Legalização
              </h2>
              <p className="text-lg font-medium text-slate-400">
                Te acompanhamos em todo o processo de legalização da sua obra
                junto ao Corpo de Bombeiros, desde a elaboração do projeto até a
                aprovação.
              </p>
            </div>
            <div className="mb-4">
              <div className="bg-red-100 w-20 h-20 rounded-lg flex justify-center items-center">
                <RiPencilRuler2Fill className="text-red-700 text-4xl" />
              </div>
              <h2 className="text-2xl font-bold text-slate-800 mt-8 mb-4">
                Projeto
              </h2>
              <p className="text-lg font-medium text-slate-400">
                Elaboramos os projetos de prevenção e combate a incêndio e
                pânico de acordo com as normas vigentes e realização de cálculo
                hidráulico.
              </p>
            </div>
            <div className="mb-4">
              <div className="bg-red-100 w-20 h-20 rounded-lg flex justify-center items-center">
                <FaFireExtinguisher className="text-red-700 text-4xl" />
              </div>
              <h2 className="text-2xl font-bold text-slate-800 mt-8 mb-4">
                Produtos
              </h2>
              <p className="text-lg font-medium text-slate-400">
                Fornecemos extintores, mangueiras, hidrantes, sprinklers,
                detectores de fumaça, alarmes de incêndio e outros equipamentos
                de prevenção e combate a incêndio.
              </p>
            </div>
            <div className="mb-4">
              <div className="bg-red-100 w-20 h-20 rounded-lg flex justify-center items-center">
                <IoMdConstruct className="text-red-700 text-4xl" />
              </div>
              <h2 className="text-2xl font-bold text-slate-800 mt-8 mb-4">
                Instalação
              </h2>
              <p className="text-lg font-medium text-slate-400">
                Instalação de sistemas de prevenção e combate a incêndio e
                pânico, detecção e alarme de incêndio, hidrantes, sprinklers e
                sinalização de emergência.
              </p>
            </div>
            <div className="mb-4">
              <div className="bg-red-100 w-20 h-20 rounded-lg flex justify-center items-center">
                <BsConeStriped className="text-red-700 text-4xl" />
              </div>
              <h2 className="text-2xl font-bold text-slate-800 mt-8 mb-4">
                Manutenção
              </h2>
              <p className="text-lg font-medium text-slate-400">
                Manutenção preventiva e corretiva de sistemas de prevenção e
                combate a incêndio e pânico para garantir a segurança e
                eficiência.
              </p>
            </div>
            <div className="mb-4">
              <div className="bg-red-100 w-20 h-20 rounded-lg flex justify-center items-center">
                <RiSearchEyeLine className="text-red-700 text-4xl" />
              </div>
              <h2 className="text-2xl font-bold text-slate-800 mt-8 mb-4">
                Vistoria
              </h2>
              <p className="text-lg font-medium text-slate-400">
                Vistoria e consultoria técnica em sistemas de prevenção e
                combate a incêndio e pânico, com emissão de laudo técnico e
                relatório fotográfico.
              </p>
            </div>
          </div>
        </section>
      </div>
      <section
        id="parceiros"
        className="bg-white py-14 md:py-32 px-8 sm:px-12 md:px-24 lg:px-64 w-full mx-auto"
      >
        <div className="text-center mb-24">
          <h1 className="text-4xl font-bold mb-6">Clientes</h1>
          <p className="text-lg font-medium text-slate-400">
            Nossos clientes são a razão de nosso sucesso.<br></br> Nosso
            compromisso é com a responsabilidade e o cuidado com seus negócios.
          </p>
        </div>
        <div className="grid xl:grid-cols-4 md:grid-cols-3 grid-cols-3 gap-8 md:gap-32 items-center justify-center">
          {clientes.map((cliente) => (
            <img
              src={cliente}
              key={cliente}
              alt="logo"
              className="grayscale hover:grayscale-0 opacity-50 hover:opacity-100 transition-all"
            />
          ))}
        </div>
      </section>
      <section
        id="contato"
        className="bg-slate-800 py-32 px-4 sm:px-12 md:px-24 lg:px-48 w-full mx-auto"
      >
        <h2 className="text-4xl font-bold text-center text-slate-50 mb-12">
          Peça seu orçamento
        </h2>
        <p className="text-lg font-medium text-slate-400 text-center mb-12">
          Conte-nos um pouco sobre seu negócio e nós entraremos em contato.
        </p>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          className="container bg-slate-50 rounded-2xl p-8"
        >
          {sending ? (
            <div className="flex justify-center items-center mb-8 bg-red-100 rounded-lg py-4">
              <FaSpinner className="animate-spin text-red-700 text-4xl" />{" "}
              <p className="text-lg font-medium text-slate-700 ml-4">
                Enviando mensagem...
              </p>
            </div>
          ) : null}
          {success ? (
            <div className="flex justify-center items-center mb-8 bg-green-100 rounded-lg py-4">
              <FaCheckCircle className="text-green-700 text-4xl" />{" "}
              <p className="text-lg font-medium text-slate-700 ml-4">
                Mensagem enviada com sucesso!
              </p>
            </div>
          ) : null}
          <div className="flex flex-col md:flex-row gap-8">
            <div className="flex flex-col flex-1">
              <div className="mb-8 flex flex-col gap-2">
                <label
                  className="text-lg font-medium text-slate-400"
                  htmlFor="nome"
                >
                  Seu nome
                </label>
                <input
                  className="border-2 border-slate-300 rounded-lg p-4"
                  id="nome"
                  name="nome"
                  type="text"
                  placeholder="Nome"
                  required
                />
              </div>
              <div className="mb-8 flex flex-col gap-2">
                <label
                  className="text-lg font-medium text-slate-400"
                  htmlFor="telefone"
                >
                  Seu telefone
                </label>
                <input
                  onChange={(e) => {
                    const value = e.target.value;
                    const newValue = value
                      .replace(/\D/g, "")
                      .replace(/(\d{2})(\d)/, "($1) $2")
                      .replace(/(\d{5})(\d)/, "$1-$2");

                    e.target.value = newValue;
                  }}
                  className="border-2 border-slate-300 rounded-lg p-4"
                  type="tel"
                  name="telefone"
                  placeholder="(21) 99999-9999"
                  pattern="([0-9]{2}) [0-9]{5}-[0-9]{4}"
                  maxLength={15}
                  required
                />
              </div>

              <div className="mb-8 flex flex-col gap-2">
                <label
                  className="text-lg font-medium text-slate-400"
                  htmlFor="email"
                >
                  Seu e-mail
                </label>
                <input
                  className="border-2 border-slate-300 rounded-lg p-4"
                  type="email"
                  name="email"
                  placeholder="email@email.com"
                  required
                />
              </div>
            </div>

            <div className="flex-1 flex flex-col gap-0">
              <label className="text-lg font-medium text-slate-400 mb-2">
                Selecione os serviços que precisa:
              </label>

              <div className="flex mb-4">
                <div className="flex flex-col flex-1 gap-4">
                  <div className="flex text-lg font-medium text-slate-600 gap-2">
                    <input type="checkbox" id="instalacao" name="instalacao" />
                    <label htmlFor="instalacao">Instalação</label>
                  </div>

                  <div className="flex text-lg font-medium text-slate-600 gap-2">
                    <input type="checkbox" id="manutencao" name="manutencao" />
                    <label htmlFor="manutencao">Manutenção</label>
                  </div>

                  <div className="flex text-lg font-medium text-slate-600 gap-2">
                    <input type="checkbox" id="vistoria" name="vistoria" />
                    <label htmlFor="vistoria">Vistoria</label>
                  </div>
                </div>
                <div className="flex flex-col flex-1 gap-4">
                  <div className="flex text-lg font-medium text-slate-600 gap-2">
                    <input type="checkbox" id="projeto" name="projeto" />
                    <label htmlFor="projeto">Projeto</label>
                  </div>

                  <div className="flex text-lg font-medium text-slate-600 gap-2">
                    <input type="checkbox" id="produtos" name="produtos" />
                    <label htmlFor="produtos">Produtos</label>
                  </div>

                  <div className="flex text-lg font-medium text-slate-600 gap-2">
                    <input type="checkbox" id="outros" name="outros" />
                    <label htmlFor="outros">Outros</label>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2 mb-4">
                <label
                  className="text-lg font-medium text-slate-400"
                  htmlFor="mensagem"
                >
                  Mensagem
                </label>

                <textarea
                  name="mensagem"
                  className="border-2 border-slate-300 rounded-lg p-4"
                  placeholder="Fale-nos um pouco sobre o que precisa."
                  required
                />
              </div>
              <div className="flex items-center gap-2 mb-8">
                <input
                  name="aceito"
                  className="border-2 border-slate-300 rounded-lg p-4"
                  type="checkbox"
                  required
                />
                <label className="text-sm font-medium text-slate-600">
                  Aceito receber comunicações da Commfire.
                </label>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-end">
            <button
              className="bg-red-700 text-red-50 py-4 px-8 rounded-lg text font-bold"
              type="submit"
            >
              Enviar
            </button>
          </div>
        </form>
        <div className="text-slate-50 flex flex-col my-8 gap-4 text-center">
          <p>Você também pode entrar em contato através do nosso WhatsApp:</p>
          <button
            className="mx-auto bg-green-600 text-green-50 py-4 px-8 rounded-lg flex items-center gap-2 font-bold"
            onClick={() => {
              window.open(
                "https://api.whatsapp.com/send?phone=5521995079200&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20da%20Commfire.",
                "_blank"
              );
            }}
          >
            <FaWhatsapp /> (21) 99507-9200
          </button>
          <p>Ou pelo e-mail: {"contato@commfire.com.br"}</p>
        </div>
      </section>
      {notasTecnicas && <NotasTecnicas grupos={NTData} loading={NTLoading} />}
      <footer className="bg-slate-800 text-slate-200 pt-8 pb-24 px-4 sm:px-12 md:px-24 lg:px-64 w-full mx-auto flex justify-between items-center">
        <img src={logoFull} alt="logo" className="w-48" />
        <p className="text-right text-lg font-medium">
          <span className="font-bold">Commfire </span>| Todos os direitos
          reservados.
        </p>
      </footer>
    </div>
  );
}

export default App;
