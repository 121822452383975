// a component that scrapes the data from https://www.cbmerj.rj.gov.br/notas-tecnicas and render as a list
import React, { useState, useEffect } from "react";
import { FaSpinner } from "react-icons/fa";
import Grupo from "./Grupo";

const NotasTecnicas = ({grupos, loading}) => {

  return (
    <div className="fixed top-32 right-0 overflow-auto w-3/4 md:w-1/3 h-3/4 z-50 rounded-l-xl bg-white shadow-lg border border-r-0 border-slate-300">
      <h1 className="sticky top-0 bg-white text-xl font-bold text-red-700 p-4 border-b border-slate-300">
        Notas Técnicas
      </h1>
      {loading && (
        <div className="text-center text-2xl font-bold text-red-700 p-4 text-center flex justify-center items-center w-full">
          <FaSpinner className="animate-spin" />
        </div>
      )}
      {
        // if there is no data, show a message
        !loading && grupos.length === 0 && (
          <div className="text-center text-2xl font-bold text-red-700 p-4 text-center">
            Não foi possível carregar os dados.
          </div>
        )
      }
      {grupos.map((grupo) => (
        <Grupo nome={grupo.title} items={grupo.links} />
      ))}
    </div>
  );
};

export default NotasTecnicas;
