import React, { useState } from "react";

const Grupo = ({ nome, items }) => {
  const [open, setOpen] = useState(false);

  return (
    <div key={nome}>
      <button
        onClick={() => setOpen(!open)}
        className="w-full text-left text-sm font-semibold text-slate-500 p-4 border-b border-slate-300"
      >
        {nome}
      </button>
      {open && (
        <ul
            className="border-b border-slate-300"
        >
          {items.map((link, index) => (
            <li className="text-sm p-4 hover:bg-slate-200 " key={index}>
              <a href={link.link} target="_blank" rel="noreferrer">{link.title}</a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Grupo;
